<template>
    <div 
        class="slider-module"
        @dblclick="setSelect()" 
        :style="getBackground"
        :key="'slider-panel-'+index"
        v-touch:swipe.left="swipeEvent"
        v-touch:swipe.right="swipeEvent"
    >
        <div class="slider-module-container" v-if="config.panel.length != 0"
              
        >
            <i 
                class="fas fa-chevron-left"  
                @click="index--"
            />
            <div 
                class="slider-module-content"
                v-if="!existCheck(index)"
            >
                <inline-edit  
                    :target="config.panel[index].title" 
                    element="h1"
                />
                <inline-edit  
                    :target="config.panel[index].subtitle" 
                    element="h2"
                />
            </div>
            <i 
                class="fas fa-chevron-right"  
                @click="index++"
            />
        </div>
        <p v-else>double click to add Slider...</p>
        <a 
            v-if="config.panel.length != 0 && config.panel[index].link != ''" 
            class="fas fa-chevron-circle-down" 
            :href="config.panel[index].link"
        />
        <div v-if="haveMarks" class="image-dots-container">
            <i 
                v-for="j in config.panel.length" 
                :key="'image-dots-' + j" 
                class="fas fa-circle" 
                :style="isFaded(j-1)"
            ></i>
        </div>
    </div>
</template>

<script>

    import * as imgManager from "@/store/imgManager";
    import { TimelineLite } from 'gsap'
    
    export default {
        props: ["config"],
        data(){
            return {
                index: 0,
                haveMarks: false,
                gsap: new TimelineLite()
            }
        },
        computed:{
            getBackground(){
                if(this.config.panel.length != 0){
                    let r = this.config.panel[this.index].rgba.r
                    let g = this.config.panel[this.index].rgba.g
                    let b = this.config.panel[this.index].rgba.b
                    let a = 1.0*(this.config.panel[this.index].rgba.a)/100.0
                    return 'background-image: linear-gradient( rgba('+r+', '+g+', '+b+', '+a+'), rgba('+r+', '+g+', '+b+', '+a+') ), url(\''+
                    this.getImg(this.config.panel[this.index].background)
                    +'\')';
                }else{
                    return "";
                }
            }
        },
        watch: {
            index(){
                if(this.index >= this.config.panel.length){
                    this.index = 0;
                }
                else if(this.index < 0){
                    this.index = this.config.panel.length - 1;
                }
            }
        },
        methods:{
            setSelect(){
                this.$store.state.selectedSlider = this.index;
            },
            isFaded(i){
                if(i == this.index)
                {
                    return "opacity: 100%;";
                }
                return "opacity: 20%;"
            },
            swipeEvent(d){
                if(d == "left")
                    this.index++
                if(d == "right")
                    this.index--
            },
            existCheck(index){
                return (this.config.panel[index].title == "" && this.config.panel[index].subtitle == "")
            },
            getImg(image){
                let img = imgManager.getImage(image);
                if(img){
                    if(img.path){
                        return img.path;
                    }else{
                        return img.file;
                    }
                }
            },      
            beforeEnter(el) {
                el.style.opacity = 0;
                el.style.display = "none";          
            },
            enter(el, done) {
                this.gsap.to(el, {
                    display: "flex",
                    opacity: 1,
                    duration: 1,
                    onComplete: done
                })
            },
            afterEnter(el){
                this.gsap.to(el, {
                    duration: 1,
                    opacity: 1
                })
            },
            leave(el, done) {
                this.gsap.to(el, {
                    opacity: 0,
                    duration: 1,
                    onComplete: done
                })
            }                  
              
        },
        mounted(){
            if(!this.config.panel){
                  this.config["panel"] = []
            }
            for(let i=0; i < this.config.panel.length; i++){
                if(!this.config.panel[i].background){
                    this.config.panel[i]["background"] = ""
                }
                if(!this.config.panel[i].title){
                    this.config.panel[i]["title"] = ""
                }
                if(typeof this.config.panel[i].title === 'string'){
                    this.config.panel[i].title = {value: this.config.panel[i].title}
                }
                if(!this.config.panel[i].subtitle){
                    this.config.panel[i]["subtitle"] = ""
                }
                if(typeof this.config.panel[i].subtitle === 'string'){
                    this.config.panel[i].subtitle = {value: this.config.panel[i].subtitle}
                }
                if(!this.config.panel[i].rgba){
                    this.config.panel[i]["rgba"] = {}
                    this.config.panel[i].rgba["r"] = 0
                    this.config.panel[i].rgba["g"] = 0
                    this.config.panel[i].rgba["b"] = 0
                    this.config.panel[i].rgba["a"] = 0
                }
                if(!this.config.panel[i].hasmark){
                    this.config.panel[i]["hasmark"] = false;
                }
                if(!this.config.panel[i].link){
                    this.config.panel[i]["link"] = ""
                }
            }
            if(this.config.timer)
            window.setInterval(() => {this.index++}, this.config.timer);
        }
    }
    
</script>